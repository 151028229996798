<div bsModal [config]="bsConfig" #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Add a new Destination</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"
                    (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content block-content-full">

          <div class="alert alert-info" *ngIf="isGooglePlaceIdInUse === true">
            <h4>Google Place Id already in use</h4>
            This place id is already in use by <a href="{{googlePlaceIdInUseUrl}}">this destination</a>.
          </div>

          <div class="alert alert-info" *ngIf="doesBoundaryExist === false">
            <h4>Boundary is missing</h4>
            Please add the boundary <a href="{{urlBoundaryAdd}}">here</a>.<br>
            <button class="btn btn-sm" (click)="getBoundaryDetailsByGooglePlaceIdAndHandleFormActivation()">Retry</button>
          </div>

          <div class="alert alert-info" *ngIf="isBoundaryApproved === false">
            <h4>Boundary is not approved</h4>
            Please approve the boundary <a href="{{urlBoundaryApproval}}">here</a>.<br>
            <button class="btn btn-sm" (click)="getBoundaryDetailsByGooglePlaceIdAndHandleFormActivation()">Retry</button>
          </div>

          <form [formGroup]="form">
            <div class="row">

              <div class="col-md-12">

                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('googleAddressText').dirty === true && form.get('googleAddressText').status === 'INVALID'}">
                  <label class="col-12" for="destination">Destination</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="destination" name="destination"
                           placeholder="Destination"
                           autocomplete="off"
                           ngx-google-places-autocomplete
                           [options]='options'
                           #placesRef="ngx-places"
                           formControlName="googleAddressText"
                           (onAddressChange)="handleGooglePlaceIdChangeOriginal($event)"
                    >
                  </div>
                  <div class="invalid-feedback"
                       *ngFor="let error of extractValidationErrorsAsArray(form.get('googleAddressText').errors)">
                    {{error}}
                  </div>
                </div>

                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('countryCode').dirty === true && form.get('countryCode').status === 'INVALID'}">
                  <label class="col-12" for="destination-add-countryCode">Related Country</label>
                  <div class="col-md-12">
                    <select type="text" class="form-control"
                            id="destination-add-countryCode"
                            name="countryCode"
                            formControlName="countryCode"
                    >
                      <option value="">Please select..</option>
                      <option *ngFor="let element of countryListEnglish"
                              value="{{element.countryCode}}">{{element.name}}</option>
                    </select>
                    <div class="invalid-feedback"
                         *ngFor="let error of extractValidationErrorsAsArray(form.get('countryCode').errors)">
                      {{error}}
                    </div>
                  </div>
                </div>

                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('priceFrom').dirty === true && form.get('priceFrom').status === 'INVALID'}">
                  <label class="col-12" for="destination-add-priceFrom">Price From</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="destination-add-priceFrom"
                           name="priceFrom"
                           placeholder="Price From" formControlName="priceFrom">
                    <div class="invalid-feedback"
                         *ngFor="let error of extractValidationErrorsAsArray(form.get('priceFrom').errors)">{{error}}
                    </div>
                  </div>
                </div>

                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('prio').dirty === true && form.get('prio').status === 'INVALID'}">
                  <label class="col-12" for="destination-add-priceFrom">Listing Priority</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="destination-add-priority"
                           name="priority"
                           placeholder="Listing Priority" formControlName="prio">
                    <div class="invalid-feedback"
                         *ngFor="let error of extractValidationErrorsAsArray(form.get('prio').errors)">{{error}}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <tabset>
                      <tab heading="German" id="tab1">
                        <!-- German Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameDe').dirty === true && form.get('destinationNameDe').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameDe">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameDe"
                                   name="destinationNameDe"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameDe"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameDe').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationDe').dirty === true && form.get('slugsDestinationDe').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationDe">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationDe"
                                   name="slugsDestinationDe"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationDe"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationDe').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="English (GB)" id="tab2">
                        <!-- EnGb Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameEnGb').dirty === true && form.get('destinationNameEnGb').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameEnGb">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameEnGb"
                                   name="destinationNameEnGb"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameEnGb"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameEnGb').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationEnGb').dirty === true && form.get('slugsDestinationEnGb').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationEnGb">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationEnGb"
                                   name="slugsDestinationEnGb"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationEnGb"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationEnGb').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="English (Int)" id="tab3">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameEn').dirty === true && form.get('destinationNameEn').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameEn">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameEn"
                                   name="destinationNameEn"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameEn"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameEn').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationEn').dirty === true && form.get('slugsDestinationEn').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationEn">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationEn"
                                   name="slugsDestinationEn"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationEn"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationEn').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="Spanish" id="tab4">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameEs').dirty === true && form.get('destinationNameEs').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameEs">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameEs"
                                   name="destinationNameEs"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameEs"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameEs').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationEs').dirty === true && form.get('slugsDestinationEs').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationEs">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationEs"
                                   name="slugsDestinationEs"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationEs"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationEs').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="Italian" id="tab5">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameIt').dirty === true && form.get('destinationNameIt').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameIt">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameIt"
                                   name="destinationNameIt"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameIt"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameIt').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationIt').dirty === true && form.get('slugsDestinationIt').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationIt">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationIt"
                                   name="slugsDestinationIt"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationIt"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationIt').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="English (Au)" id="tab6">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameEnAu').dirty === true && form.get('destinationNameEnAu').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameEnAu">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameEnAu"
                                   name="destinationNameEnAu"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameEnAu"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameEnAu').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationEnAu').dirty === true && form.get('slugsDestinationEnAu').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationEnAu">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationEnAu"
                                   name="slugsDestinationEnAu"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationEnAu"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationEnAu').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="Polish" id="tab7">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNamePl').dirty === true && form.get('destinationNamePl').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNamePl">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNamePl"
                                   name="destinationNamePl"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNamePl"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNamePl').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationPl').dirty === true && form.get('slugsDestinationPl').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationPl">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationPl"
                                   name="slugsDestinationPl"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationPl"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationPl').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="French" id="tab8">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameFr').dirty === true && form.get('destinationNameFr').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameFr">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameFr"
                                   name="destinationNameFr"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameFr"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameFr').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationFr').dirty === true && form.get('slugsDestinationFr').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationFr">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationFr"
                                   name="slugsDestinationFr"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationFr"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationFr').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                      <tab heading="Dutch" id="tab9">
                        <!-- En Tab -->
                        <div class="row">
                          <div class="col-12">&nbsp;</div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('destinationNameNl').dirty === true && form.get('destinationNameNl').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-destinationNameNl">Destination Name</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-destinationNameNl"
                                   name="destinationNameNl"
                                   placeholder="Destination Name"
                                   autocomplete="off"
                                   formControlName="destinationNameNl"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('destinationNameNl').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>

                        <div class="form-group row"
                             [ngClass]="{'is-invalid': form.get('slugsDestinationNl').dirty === true && form.get('slugsDestinationNl').status === 'INVALID'}">
                          <label class="col-12" for="destination-add-slugsDestinationNl">Destination Slug</label>
                          <div class="col-md-12">
                            <input type="text" class="form-control"
                                   id="destination-add-slugsDestinationNl"
                                   name="slugsDestinationNl"
                                   placeholder="Destination Slug"
                                   autocomplete="off"
                                   formControlName="slugsDestinationNl"
                            >
                            <div class="invalid-feedback"
                                 *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsDestinationNl').errors)">
                              {{error}}
                            </div>
                          </div>
                        </div>
                      </tab>

                    </tabset>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <hr>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12">Destination Type Related Information</label>

                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input"
                             type="checkbox"
                             name="isCountry"
                             id="destination-add-iscountry"
                             formControlName="isCountry">
                      <label class="custom-control-label" for="destination-add-iscountry">Is Country</label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input"
                             type="checkbox"
                             name="isFederalState"
                             id="destination-add-isfederalstate"
                             formControlName="isFederalState">
                      <label class="custom-control-label" for="destination-add-isfederalstate">Is Federal State</label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input"
                             type="checkbox"
                             name="isCapital"
                             id="destination-add-iscapital"
                             formControlName="isCapital">
                      <label class="custom-control-label" for="destination-add-iscapital">Is Capital</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-12">General Settings</label>

                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input"
                             type="checkbox"
                             name="hasImage"
                             id="destination-add-hasimage"
                             formControlName="hasHeaderImage">
                      <label class="custom-control-label" for="destination-add-hasimage">Has Image</label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input"
                             type="checkbox"
                             name="hasImage"
                             id="destination-add-islistdestination"
                             formControlName="isListDestination">
                      <label class="custom-control-label" for="destination-add-islistdestination">Is List
                        Destination</label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input"
                             type="checkbox"
                             name="isEnabled"
                             id="destination-add-isenabled"
                             formControlName="isEnabled">
                      <label class="custom-control-label" for="destination-add-isenabled">Is Enabled</label>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()" [disabled]="doesBoundaryExist === false || isBoundaryApproved === false">
          <i class="fa fa-check"></i> Add Destination
        </button>
      </div>
    </div>
  </div>
</div>


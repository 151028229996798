import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { EventsService } from '../../../services/events/events.service';
import { LoadingIndicatorService } from '../../../services/loading-indicator/loading-indicator.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'tripmakery-user-add-user-modal',
  templateUrl: './user-add-user-modal.component.html',
})
export class UserAddUserModalComponent {
  public bookingChangeRequestId: string;
  public bookingChangeRequestData: any;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;


  public bsConfig = {
    ignoreBackdropClick: true,
    backdrop: true,
  };

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router
  ) {
    this.form = new FormGroup({
      'firstName': new FormControl({value: '', disabled: false}),
      'lastName': new FormControl({value: '', disabled: false}),
      'salutation': new FormControl({value: '', disabled: false}),
      'language': new FormControl({value: '', disabled: false}),
      'email': new FormControl({value: '', disabled: false}),
      'callingCode': new FormControl({value: '', disabled: false}),
      'phone': new FormControl({value: '', disabled: false}),
      'passwordA': new FormControl({value: '', disabled: false}),
      'passwordB': new FormControl({value: '', disabled: false}),
      'isB2BAccount': new FormControl({value: false, disabled: false}),
      'isHousingUserAccount': new FormControl({value: false, disabled: false}),
    });
    this.environment_ = environment;
  }

  public showChildModal(): void {
    this.isEmailInUse = false;
    this.form.reset();

    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/user', {
      firstName: this.createNullIfEmptyString(this.form.get('firstName').value),
      lastName: this.createNullIfEmptyString(this.form.get('lastName').value),
      salutation: this.createNullIfEmptyString(this.form.get('salutation').value),
      language: this.createNullIfEmptyString(this.form.get('language').value),
      email: this.createNullIfEmptyString(this.form.get('email').value),
      callingCode: this.createNullIfEmptyString(this.form.get('callingCode').value),
      phone: this.createNullIfEmptyString(this.form.get('phone').value),
      passwordA: this.createNullIfEmptyString(this.form.get('passwordA').value),
      passwordB: this.createNullIfEmptyString(this.form.get('passwordB').value),
      isB2BAccount: this.createFalseIfNull(this.form.get('isB2BAccount').value),
      isHousingUserAccount: this.createFalseIfNull(this.form.get('isHousingUserAccount').value),
    })
      .subscribe(
        (response: any) => {
          this.router.navigate(['users', response.user._id]);

        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

                console.log(error.property, error.constraints);
              }
            } catch (error) {
              console.log('Catched E', error);
            }
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_EMAIL_ALREADY_IN_USE') {
            this.isEmailInUse = true;
            this.emailInUseUserId = err.error.userId;
            this.emailInUseUrl = environment.urls.tool + '/users/' + err.error.userId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
          this.hideChildModal();
        }
      );
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfNull(element) {
    if (element == null) {
      return false;
    }
    return element;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../../services/events/events.service';
import { environment } from '../../../../../../environments/environment';
import { FieldsBasicPlaceDetails } from '../../../../../shared/consts/fields-basic-place-details';
import { FormUtils } from '../../../../../shared/utils/form-utils';
import { EventType, GroupTypeFrontend } from '@tripbakers/enums';

@Component({
  selector: 'tripmakery-bl-keyword-info-bl-keyword-edit',
  templateUrl: './bl-keyword-info-bl-keyword-edit.component.html',
})
export class BlKeywordInfoBlKeywordEditComponent implements OnInit {
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;

  @Input() pageBlKeyword;
  public sortingAlgorithms: string[] = [];
  public preferredGroupTypes = Object.values(GroupTypeFrontend);
  public preferredEventTypes = Object.values(EventType);

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
  ) {
    this.environment_ = environment;
  }

  public ngOnInit() {
    this.form = new FormGroup({
      'keyword': new FormControl({value: this.pageBlKeyword.keyword, disabled: false}),

      'slugsKeywordDe': new FormControl({value: this.pageBlKeyword.slugsKeyword.de, disabled: true}),
      'slugsKeywordEn': new FormControl({value: this.pageBlKeyword.slugsKeyword.en, disabled: true}),
      'slugsKeywordEnGb': new FormControl({value: this.pageBlKeyword.slugsKeyword['en-gb'], disabled: true}),
      'slugsKeywordEs': new FormControl({value: this.pageBlKeyword.slugsKeyword.es, disabled: true}),
      'slugsKeywordIt': new FormControl({value: this.pageBlKeyword.slugsKeyword.it, disabled: true}),
      'slugsKeywordEnAu': new FormControl({value: this.pageBlKeyword.slugsKeyword['en-au'], disabled: true}),
      'slugsKeywordPl': new FormControl({value: this.pageBlKeyword.slugsKeyword.pl, disabled: true}),
      'slugsKeywordFr': new FormControl({value: this.pageBlKeyword.slugsKeyword.fr, disabled: true}),
      'slugsKeywordNl': new FormControl({value: this.pageBlKeyword.slugsKeyword.nl, disabled: true}),

      'keywordNameDe': new FormControl({value: this.pageBlKeyword.keywordName.de, disabled: false}),
      'keywordNameEn': new FormControl({value: this.pageBlKeyword.keywordName.en, disabled: false}),
      'keywordNameEnGb': new FormControl({value: this.pageBlKeyword.keywordName['en-gb'], disabled: false}),
      'keywordNameEs': new FormControl({value: this.pageBlKeyword.keywordName.es, disabled: false}),
      'keywordNameIt': new FormControl({value: this.pageBlKeyword.keywordName.it, disabled: false}),
      'keywordNameEnAu': new FormControl({value: this.pageBlKeyword.keywordName['en-au'], disabled: false}),
      'keywordNamePl': new FormControl({value: this.pageBlKeyword.keywordName.pl, disabled: false}),
      'keywordNameFr': new FormControl({value: this.pageBlKeyword.keywordName.fr, disabled: false}),
      'keywordNameNl': new FormControl({value: this.pageBlKeyword.keywordName.nl, disabled: false}),

      'keywordNamePluralDe': new FormControl({value: this.pageBlKeyword.keywordNamePlural.de, disabled: false}),
      'keywordNamePluralEn': new FormControl({value: this.pageBlKeyword.keywordNamePlural.en, disabled: false}),
      'keywordNamePluralEnGb': new FormControl({value: this.pageBlKeyword.keywordNamePlural['en-gb'], disabled: false}),
      'keywordNamePluralEs': new FormControl({value: this.pageBlKeyword.keywordNamePlural.es, disabled: false}),
      'keywordNamePluralIt': new FormControl({value: this.pageBlKeyword.keywordNamePlural.it, disabled: false}),
      'keywordNamePluralEnAu': new FormControl({value: this.pageBlKeyword.keywordNamePlural['en-au'], disabled: false}),
      'keywordNamePluralPl': new FormControl({value: this.pageBlKeyword.keywordNamePlural.pl, disabled: false}),
      'keywordNamePluralFr': new FormControl({value: this.pageBlKeyword.keywordNamePlural.fr, disabled: false}),
      'keywordNamePluralNl': new FormControl({value: this.pageBlKeyword.keywordNamePlural.nl, disabled: false}),

      'housingSortingAlgorithm': new FormControl({value: this.pageBlKeyword.housingSortingAlgorithm, disabled: false}),
      'keywordCategory': new FormControl({value: this.pageBlKeyword.keywordCategory.toString(), disabled: false}),
      'isEnabled': new FormControl({value: this.pageBlKeyword.isEnabled, disabled: false}),

      'preferredGroupType': new FormControl({value: this.pageBlKeyword.preferredGroupType == null ? '' : this.pageBlKeyword.preferredGroupType, disabled: false}),
      'preferredEventType': new FormControl({value: this.pageBlKeyword.preferredEventType == null ? '' : this.pageBlKeyword.preferredEventType, disabled: false}),
    });
    this.handleReceiveSortingAlgorithms();
  }


  public handleClick() {
    this.showLoading();
    const body = this.createCreationBody();
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/pages/bl-keyword/' + this.pageBlKeyword._id, body)
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('blKeywordInfoReloadBlKeywordData');
        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

                console.log(error.property, error.constraints);
              }
            } catch (error) {
              console.log('Catched E', error);
            }
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_EMAIL_ALREADY_IN_USE') {
            this.isEmailInUse = true;
            this.emailInUseUserId = err.error.userId;
            this.emailInUseUrl = environment.urls.tool + '/users/' + err.error.userId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  public handleReceiveSortingAlgorithms() {
    /* */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/bl-keyword/sorting-algorithms')
      .subscribe(
        (response: any) => {
          this.sortingAlgorithms = response.sortingAlgorithms;
        },
        (err) => {
          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  public extractValidationErrorsAsArray(errors) {
    return FormUtils.extractValidationErrorsAsArray(errors);
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createCreationBody() {

    return {
      /* */
      keyword: FormUtils.createNullIfEmptyString(this.form.get('keyword').value),

      slugsKeywordDe: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordDe').value),
      slugsKeywordEn: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEn').value),
      slugsKeywordEnGb: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEnGb').value),
      slugsKeywordEs: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEs').value),
      slugsKeywordIt: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordIt').value),
      slugsKeywordEnAu: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEnAu').value),
      slugsKeywordPl: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordPl').value),
      slugsKeywordFr: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordFr').value),
      slugsKeywordNl: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordNl').value),

      keywordNameDe: FormUtils.createNullIfEmptyString(this.form.get('keywordNameDe').value),
      keywordNameEn: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEn').value),
      keywordNameEnGb: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEnGb').value),
      keywordNameEs: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEs').value),
      keywordNameIt: FormUtils.createNullIfEmptyString(this.form.get('keywordNameIt').value),
      keywordNameEnAu: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEnAu').value),
      keywordNamePl: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePl').value),
      keywordNameFr: FormUtils.createNullIfEmptyString(this.form.get('keywordNameFr').value),
      keywordNameNl: FormUtils.createNullIfEmptyString(this.form.get('keywordNameNl').value),

      keywordNamePluralDe: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralDe').value),
      keywordNamePluralEn: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEn').value),
      keywordNamePluralEnGb: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEnGb').value),
      keywordNamePluralEs: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEs').value),
      keywordNamePluralIt: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralIt').value),
      keywordNamePluralEnAu: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEnAu').value),
      keywordNamePluralPl: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralPl').value),
      keywordNamePluralFr: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralFr').value),
      keywordNamePluralNl: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralNl').value),

      housingSortingAlgorithm: FormUtils.createNullIfEmptyString(this.form.get('housingSortingAlgorithm').value),
      keywordCategory: FormUtils.returnAsNumberOrNullIfEmptyOrNoNumber(this.form.get('keywordCategory').value),

      isEnabled: FormUtils.createFalseIfNull(this.form.get('isEnabled').value),

      preferredGroupType: FormUtils.createNullIfEmptyString(this.form.get('preferredGroupType').value),
      preferredEventType: FormUtils.createNullIfEmptyString(this.form.get('preferredEventType').value),
    };
  }

}

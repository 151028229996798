<div class="block" *ngIf="form != null">
  <div class="block-header block-header-default">
    <h3 class="block-title">Edit Keyword</h3>
    <div class="btn-group">
    </div>
  </div>
  <div class="block-content block-content-full">

    <form [formGroup]="form">
      <div class="row">

        <div class="col-md-12">

          <!-- -->
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('keyword').dirty === true && form.get('keyword').status === 'INVALID'}">
            <label class="col-12" for="keyword">Keyword (Internal)</label>
            <div class="col-12">
              <input type="text" class="form-control" id="keyword"
                     name="keyword"
                     placeholder="Internal name of the keyword" formControlName="keyword">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('keyword').errors)">{{error}}
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <tabset>
                <tab heading="German" id="tab1">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameDe').dirty === true && form.get('keywordNameDe').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameDe">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameDe"
                             name="keywordNameDe"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameDe"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameDe').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralDe').dirty === true && form.get('keywordNamePluralDe').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralDe">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralDe"
                             name="keywordNamePluralDe"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralDe"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralDe').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordDe').dirty === true && form.get('slugsKeywordDe').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordDe">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordDe"
                             name="slugsKeywordDe"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordDe"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordDe').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="English (Int)" id="tab2">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameEn').dirty === true && form.get('keywordNameEn').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameEn">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameEn"
                             name="keywordNameEn"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameEn"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameEn').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralEn').dirty === true && form.get('keywordNamePluralEn').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralEn">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralEn"
                             name="keywordNamePluralEn"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralEn"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralEn').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordEn').dirty === true && form.get('slugsKeywordEn').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordEn">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordEn"
                             name="slugsKeywordEn"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordEn"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordEn').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="English (GB)" id="tab3">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameEnGb').dirty === true && form.get('keywordNameEnGb').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameEnGb">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameEnGb"
                             name="keywordNameEnGb"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameEnGb"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameEnGb').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralEnGb').dirty === true && form.get('keywordNamePluralEnGb').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralEnGb">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralEnGb"
                             name="keywordNamePluralEnGb"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralEnGb"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralEnGb').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordEnGb').dirty === true && form.get('slugsKeywordEnGb').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordEn">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordEnGb"
                             name="slugsKeywordEnGb"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordEnGb"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordEnGb').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="Spanish" id="tab4">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameEs').dirty === true && form.get('keywordNameEs').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameEs">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameEs"
                             name="keywordNameEs"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameEs"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameEs').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralEs').dirty === true && form.get('keywordNamePluralEs').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralEs">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralEs"
                             name="keywordNamePluralEs"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralEs"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralEs').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordEs').dirty === true && form.get('slugsKeywordEs').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordEs">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordEs"
                             name="slugsKeywordEs"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordEs"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordEs').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="Italian" id="tab5">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameIt').dirty === true && form.get('keywordNameIt').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameEs">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameIt"
                             name="keywordNameIt"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameIt"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameIt').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralIt').dirty === true && form.get('keywordNamePluralIt').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralEs">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralIt"
                             name="keywordNamePluralIt"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralIt"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralIt').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordIt').dirty === true && form.get('slugsKeywordIt').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordEs">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordIt"
                             name="slugsKeywordIt"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordIt"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordIt').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="English (AUS)" id="tab6">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameEnAu').dirty === true && form.get('keywordNameEnAu').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameEnAu">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameEnAu"
                             name="keywordNameEnAu"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameEnAu"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameEnAu').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralEnAu').dirty === true && form.get('keywordNamePluralEnAu').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralEnAu">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralEnAu"
                             name="keywordNamePluralEnAu"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralEnAu"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralEnAu').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordEnAu').dirty === true && form.get('slugsKeywordEnAu').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordEnAu">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordEnAu"
                             name="slugsKeywordIt"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordEnAu"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordEnAu').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="Polish" id="tab7">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePl').dirty === true && form.get('keywordNamePl').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePl">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePl"
                             name="keywordNamePl"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNamePl"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePl').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralPl').dirty === true && form.get('keywordNamePluralPl').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralPl">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralPl"
                             name="keywordNamePluralPl"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralPl"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralPl').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordPl').dirty === true && form.get('slugsKeywordPl').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordPl">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordPl"
                             name="slugsKeywordPl"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordPl"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordPl').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="French" id="tab8">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameFr').dirty === true && form.get('keywordNameFr').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameFr">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameFr"
                             name="keywordNameFr"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameFr"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameFr').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralFr').dirty === true && form.get('keywordNamePluralFr').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralFr">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralFr"
                             name="keywordNamePluralFr"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralFr"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralFr').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordFr').dirty === true && form.get('slugsKeywordFr').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordFr">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordFr"
                             name="slugsKeywordFr"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordFr"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordFr').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="Dutch" id="tab9">
                  <!-- German Tab -->
                  <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNameNl').dirty === true && form.get('keywordNameNl').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNameNl">Keyword Name</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNameNl"
                             name="keywordNameNl"
                             placeholder="Keyword Name"
                             autocomplete="off"
                             formControlName="keywordNameNl"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNameNl').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('keywordNamePluralNl').dirty === true && form.get('keywordNamePluralNl').status === 'INVALID'}">
                    <label class="col-12" for="add-keywordNamePluralNl">Keyword Name Plural</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-keywordNamePluralNl"
                             name="keywordNamePluralNl"
                             placeholder="Keyword Name Plural"
                             autocomplete="off"
                             formControlName="keywordNamePluralNl"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('keywordNamePluralNl').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row"
                       [ngClass]="{'is-invalid': form.get('slugsKeywordNl').dirty === true && form.get('slugsKeywordNl').status === 'INVALID'}">
                    <label class="col-12" for="add-slugsKeywordNl">Keyword Slug</label>
                    <div class="col-md-12">
                      <input type="text" class="form-control"
                             id="add-slugsKeywordNl"
                             name="slugsKeywordNl"
                             placeholder="Keyword Slug"
                             autocomplete="off"
                             formControlName="slugsKeywordNl"
                      >
                      <div class="invalid-feedback"
                           *ngFor="let error of extractValidationErrorsAsArray(form.get('slugsKeywordNl').errors)">
                        {{error}}
                      </div>
                    </div>
                  </div>
                </tab>

              </tabset>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>

          <div class="row">
            <label class="col-12">General Settings</label>

            <div class="col-12">
              <div class="form-group row">
                <label class="col-12">Keyword Category</label>
                <div class="col-12">
                  <select class="form-control" formControlName="keywordCategory">
                    <option value="0">Hotel Type</option>
                    <option value="1">Travel Type</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group row">
                <label class="col-12">Preferred Group Type</label>
                <div class="col-12">
                  <select class="form-control" formControlName="preferredGroupType">
                    <option>Please select..</option>
                    <option value="">None</option>
                    <option *ngFor="let element of preferredGroupTypes"
                            value="{{element}}">{{element}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group row">
                <label class="col-12">Preferred Event Type</label>
                <div class="col-12">
                  <select class="form-control" formControlName="preferredEventType">
                    <option>Please select..</option>
                    <option value="">None</option>
                    <option *ngFor="let element of preferredEventTypes"
                            value="{{element}}">{{element}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group row">
                <label class="col-12">Sorting Algorithm</label>
                <div class="col-12">
                  <select class="form-control" formControlName="housingSortingAlgorithm" *ngIf="sortingAlgorithms != null">
                    <option>Please select..</option>
                    <option *ngFor="let element of sortingAlgorithms"
                            value="{{element}}">{{element}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input"
                       type="checkbox"
                       name="isEnabled"
                       id="destination-add-isenabled"
                       formControlName="isEnabled">
                <label class="custom-control-label" for="destination-add-isenabled">Is Enabled</label>
              </div>
            </div>

          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="block-content block-content-full bg-body-light">
    <button class="btn btn-success" (click)="handleClick()">Save</button>
  </div>
</div>

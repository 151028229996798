import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HousingInfoService} from '../../../../../services/housing-info/housing-info.service';
import {LoadingIndicatorService} from '../../../../../services/loading-indicator/loading-indicator.service';
import {EventsService} from '../../../../../services/events/events.service';

@Component({
  selector: 'app-housing-info-details-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.css']
})
export class HousingInfoDetailsPaymentSettingsComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'isPaymentEnabled': new FormControl({value: false, disabled: false}),
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      console.log('INVALID');
      this.validateAllFormFields(this.form);
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === 'E_VALIDATION_FAILED') {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log('Catched E', error);
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
      }
    );
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/payment-details', {
      isPaymentEnabled: this.form.get('isPaymentEnabled').value,
    });
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  private mapHousingToForm() {
    const vals = {
      isPaymentEnabled: this.housing.internalInfo.paymentDetails.isPaymentEnabled == null ? false : this.housing.internalInfo.paymentDetails.isPaymentEnabled,
    };
    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public returnEmptyStringWhenNull(element) {
    return (element == null) ? '' : element;
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {

  constructor() {

  }

  public countryListAlpha2English = {
    'AF': 'Afghanistan',
    'AL': 'Albania',
    'DZ': 'Algeria',
    'AS': 'American Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua and Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaijan',
    'BS': 'Bahamas (the)',
    'BH': 'Bahrain',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Belarus',
    'BE': 'Belgium',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivia',
    'BQ': 'Bonaire',
    'BA': 'Bosnia and Herzegovina',
    'BW': 'Botswana',
    'BV': 'Bouvet Island',
    'BR': 'Brazil',
    'IO': 'British Indian Ocean Territory',
    'BN': 'Brunei Darussalam',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Cabo Verde',
    'KH': 'Cambodia',
    'CM': 'Cameroon',
    'CA': 'Canada',
    'KY': 'Cayman Islands',
    'CF': 'Central African Republic',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Christmas Island',
    'CC': 'Cocos (Keeling) Islands',
    'CO': 'Colombia',
    'KM': 'Comoros',
    'CD': 'Congo',
    'CG': 'Congo',
    'CK': 'Cook Islands',
    'CR': 'Costa Rica',
    'HR': 'Croatia',
    'CU': 'Cuba',
    'CW': 'Curaçao',
    'CY': 'Cyprus',
    'CZ': 'Czechia',
    'CI': 'Côte d\'Ivoire',
    'DK': 'Denmark',
    'DJ': 'Djibouti',
    'DM': 'Dominica',
    'DO': 'Dominican Republic',
    'EC': 'Ecuador',
    'EG': 'Egypt',
    'SV': 'El Salvador',
    'GQ': 'Equatorial Guinea',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'SZ': 'Eswatini',
    'ET': 'Ethiopia',
    'FK': 'Falkland Islands',
    'FO': 'Faroe Islands',
    'FJ': 'Fiji',
    'FI': 'Finland',
    'FR': 'France',
    'GF': 'French Guiana',
    'PF': 'French Polynesia',
    'TF': 'French Southern Territories',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Germany',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Greece',
    'GL': 'Greenland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Heard Island and McDonald Islands',
    'VA': 'Holy See',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hungary',
    'IS': 'Iceland',
    'IN': 'India',
    'ID': 'Indonesia',
    'IR': 'Iran',
    'IQ': 'Iraq',
    'IE': 'Ireland',
    'IM': 'Isle of Man',
    'IL': 'Israel',
    'IT': 'Italy',
    'JM': 'Jamaica',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'KZ': 'Kazakhstan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KP': 'North-Korea',
    'KR': 'South-Korea',
    'KW': 'Kuwait',
    'KG': 'Kyrgyzstan',
    'LA': 'Lao',
    'LV': 'Latvia',
    'LB': 'Lebanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libya',
    'LI': 'Liechtenstein',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MO': 'Macao',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Maldives',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshall Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexico',
    'FM': 'Micronesia',
    'MD': 'Moldova',
    'MC': 'Monaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Morocco',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Netherlands',
    'NC': 'New Caledonia',
    'NZ': 'New Zealand',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolk Island',
    'MP': 'Northern Mariana Islands',
    'NO': 'Norway',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestine, State of',
    'PA': 'Panama',
    'PG': 'Papua New Guinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Philippines',
    'PN': 'Pitcairn',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'MK': 'Republic of North Macedonia',
    'RO': 'Romania',
    'RU': 'Russian Federation',
    'RW': 'Rwanda',
    'RE': 'Réunion',
    'BL': 'Saint Barthélemy',
    'SH': 'Saint Helena, Ascension and Tristan da Cunha',
    'KN': 'Saint Kitts and Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint Martin',
    'PM': 'Saint Pierre and Miquelon',
    'VC': 'Saint Vincent and the Grenadines',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tome and Principe',
    'SA': 'Saudi Arabia',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SX': 'Sint Maarten',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'SB': 'Solomon Islands',
    'SO': 'Somalia',
    'ZA': 'South Africa',
    'GS': 'South Georgia and the South Sandwich Islands',
    'SS': 'South Sudan',
    'ES': 'Spain',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard and Jan Mayen',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'SY': 'Syrian Arab Republic',
    'TW': 'Taiwan',
    'TJ': 'Tajikistan',
    'TZ': 'Tanzania, United Republic of',
    'TH': 'Thailand',
    'TL': 'Timor-Leste',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad and Tobago',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TM': 'Turkmenistan',
    'TC': 'Turks and Caicos Islands',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraine',
    'AE': 'United Arab Emirates',
    'GB': 'United Kingdom of Great Britain and Northern Ireland',
    'UM': 'United States Minor Outlying Islands',
    'US': 'United States of America',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Viet Nam',
    'VG': 'Virgin Islands',
    'VI': 'Virgin Islands',
    'WF': 'Wallis and Futuna',
    'EH': 'Western Sahara',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe',
    'AX': 'Åland Islands'
  };
  public countryListAlpha2German = {
    'AF': 'Afghanistan',
    'AL': 'Albanien',
    'DZ': 'Algerien',
    'AS': 'Amerikanisch-Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua und Barbuda',
    'AR': 'Argentinien',
    'AM': 'Armenien',
    'AW': 'Aruba',
    'AU': 'Australien',
    'AT': 'Österreich',
    'AZ': 'Aserbaidschan',
    'BS': 'Bahamas',
    'BH': 'Bahrain',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Belarus',
    'BE': 'Belgien',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivien',
    'BQ': 'Karibische Niederlande',
    'BA': 'Bosnien und Herzegowina',
    'BW': 'Botswana',
    'BV': 'Bouvetinsel',
    'BR': 'Brasilien',
    'IO': 'Britisches Territorium im Indischen Ozean',
    'BN': 'Brunei Darussalam',
    'BG': 'Bulgarien',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Kap Verde',
    'KH': 'Kambodscha',
    'CM': 'Kamerun',
    'CA': 'Kanada',
    'KY': 'Kaimaninseln ',
    'CF': 'Zentralafrikanische Republik',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Weihnachtsinsel',
    'CC': 'Kokosinsel',
    'CO': 'Kolumbien',
    'KM': 'Komoren',
    'CD': 'Demokratische Republik Kongo',
    'CG': 'Kongo',
    'CK': 'Cookinseln',
    'CR': 'Costa Rica',
    'HR': 'Kroatien',
    'CU': 'Kuba',
    'CW': 'Curaçao',
    'CY': 'Zypern',
    'CZ': 'Tschechien',
    'CI': 'Côte d\'Ivoire',
    'DK': 'Dänemark',
    'DJ': 'Dschibuti',
    'DM': 'Dominica',
    'DO': 'Dominikanische Republik',
    'EC': 'Ecuador',
    'EG': 'Ägypten',
    'SV': 'El Salvador',
    'GQ': 'Äquatorialguinea',
    'ER': 'Eritrea',
    'EE': 'Estland',
    'SZ': 'Swasiland',
    'ET': 'Äthiopien',
    'FK': 'Falklandinseln',
    'FO': 'Färöer',
    'FJ': 'Fiji',
    'FI': 'Finnland',
    'FR': 'Frankreich',
    'GF': 'Französisch-Guyana',
    'PF': 'Französisch-Polynesien',
    'TF': 'Französische Süd- und Antarktisgebiete',
    'GA': 'Gabun',
    'GM': 'Gambia',
    'GE': 'Georgien',
    'DE': 'Deutschland',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Griechenland',
    'GL': 'Grönland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Heard und McDonaldinseln',
    'VA': 'Vatikanstadt',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Ungarn',
    'IS': 'Island',
    'IN': 'Indien',
    'ID': 'Indonesien',
    'IR': 'Iran',
    'IQ': 'Irak',
    'IE': 'Irland',
    'IM': 'Isle of Man',
    'IL': 'Israel',
    'IT': 'Italien',
    'JM': 'Jamaika',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'KZ': 'Kasachstan',
    'KE': 'Kenia',
    'KI': 'Kiribati',
    'KP': 'Nordkorea',
    'KR': 'Südkorea',
    'KW': 'Kuwait',
    'KG': 'Kirgisistan',
    'LA': 'Laos',
    'LV': 'Lettland',
    'LB': 'Libanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libyen',
    'LI': 'Liechtenstein',
    'LT': 'Litauen',
    'LU': 'Luxemburg',
    'MO': 'Macao',
    'MG': 'Madagaskar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Malediven',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshallinseln',
    'MQ': 'Martinique',
    'MR': 'Mauretanien',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexiko',
    'FM': 'Föderierte Staaten von Mikronesien',
    'MD': 'Moldau',
    'MC': 'Monaco',
    'MN': 'Mongolei',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Marokko',
    'MZ': 'Mozambik',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Niederlande',
    'NC': 'Neukaledonien',
    'NZ': 'Neuseeland',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolkinsel',
    'MP': 'Nördliche Marianen',
    'NO': 'Norwegen',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palästina',
    'PA': 'Panama',
    'PG': 'Papua-Neuguinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Philippinen',
    'PN': 'Pitcairninseln',
    'PL': 'Polen',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'MK': 'Nordmazedonien',
    'RO': 'Rumänien',
    'RU': 'Russland',
    'RW': 'Ruanda',
    'RE': 'Réunion',
    'BL': 'Saint-Barthélemy',
    'SH': 'Sankt Helena',
    'KN': 'Sankt Kitts und Nevis',
    'LC': 'Sankt Lucia',
    'MF': 'Sankt Martin',
    'PM': 'Sankt Pierre und Miquelon',
    'VC': 'Sankt Vincent und die Grenadinen',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'São Tomé und Príncipe',
    'SA': 'Saudi-Arabien',
    'SN': 'Senegal',
    'RS': 'Serbien',
    'SC': 'Seychellen',
    'SL': 'Sierra Leone',
    'SG': 'Singapur',
    'SX': 'Sint Maarten',
    'SK': 'Slowakei',
    'SI': 'Slowenien',
    'SB': 'Salomonen',
    'SO': 'Somalia',
    'ZA': 'Südafrika',
    'GS': 'Südgeorgien und die Südlichen Sandwichinseln',
    'SS': 'Südsudan',
    'ES': 'Spanien',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard und Jan Mayen',
    'SE': 'Schweden',
    'CH': 'Schweiz',
    'SY': 'Syrien',
    'TW': 'Taiwan',
    'TJ': 'Tadschikistan',
    'TZ': 'Tansania',
    'TH': 'Thailand',
    'TL': 'Osttimor',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad und Tobago',
    'TN': 'Tunesien',
    'TR': 'Türkei',
    'TM': 'Turkmenistan',
    'TC': 'Turks- und Caicosinseln',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraine',
    'AE': 'Vereinigte Arabische Emirate',
    'GB': 'Vereinigtes Königreich',
    'UM': 'Kleinere Inselbesitzungen der Vereinigten Staaten',
    'US': 'Vereinigte Staaten',
    'UY': 'Uruguay',
    'UZ': 'Usbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VG': 'Britische Jungferninseln',
    'VI': 'Amerikanische Jungferninseln',
    'WF': 'Wallis und Futuna',
    'EH': 'Westsahara',
    'YE': 'Jemen',
    'ZM': 'Sambia',
    'ZW': 'Simbabwe',
    'AX': 'Åland'
  };
  public countryListAlpha2Spanish = {
    'AF': 'Afganistán',
    'AL': 'Albania',
    'DZ': 'Argelia',
    'AS': 'Samoa Americana',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguila',
    'AQ': 'Antártida',
    'AG': 'Antigua y Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaiyán',
    'BS': 'Bahamas',
    'BH': 'Baréin',
    'BD': 'Bangladés',
    'BB': 'Barbados',
    'BY': 'Bielorrusia',
    'BE': 'Bélgica',
    'BZ': 'Belice',
    'BJ': 'Benín',
    'BM': 'Bermudas',
    'BT': 'Bután',
    'BO': 'Bolivia',
    'BQ': 'Caribe Neerlandés',
    'BA': 'Bosnia y Herzegovina',
    'BW': 'Botsuana',
    'BV': 'Isla Bouvet',
    'BR': 'Brasil',
    'IO': 'Territorio Británico del Océano Índico',
    'BN': 'Brunéi',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Cabo Verde',
    'KH': 'Camboya',
    'CM': 'Camerún',
    'CA': 'Canadá',
    'KY': 'Islas Caimán',
    'CF': 'República Centroafricana',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Isla de Navidad',
    'CC': 'Islas Cocos',
    'CO': 'Colombia',
    'KM': 'Comoras',
    'CD': 'República Democrática del Congo',
    'CG': 'Congo',
    'CK': 'Islas Cook',
    'CR': 'Costa Rica',
    'HR': 'Croacia',
    'CU': 'Cuba',
    'CW': 'Curazao',
    'CY': 'Chipre',
    'CZ': 'Chequia',
    'CI': 'Costa de Marfil',
    'DK': 'Dinamarca',
    'DJ': 'Yibuti',
    'DM': 'Dominica',
    'DO': 'República Dominicana',
    'EC': 'Ecuador',
    'EG': 'Egipto',
    'SV': 'El Salvador',
    'GQ': 'Guinea Ecuatorial',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'SZ': 'Esuatini',
    'ET': 'Etiopía',
    'FK': 'Islas Malvinas',
    'FO': 'Islas Feroe',
    'FJ': 'Fiyi',
    'FI': 'Finlandia',
    'FR': 'Francia',
    'GF': 'Guayana Francesa',
    'PF': 'Polinesia Francesa',
    'TF': 'Territorios Australes Franceses',
    'GA': 'Gabón',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Alemania',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Grecia',
    'GL': 'Groenlandia',
    'GD': 'Granada',
    'GP': 'Guadalupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bisáu',
    'GY': 'Guyana',
    'HT': 'Haití',
    'HM': 'Islas Heard y McDonald',
    'VA': 'Ciudad del Vaticano',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hungría',
    'IS': 'Islandia',
    'IN': 'India',
    'ID': 'Indonesia',
    'IR': 'Irán',
    'IQ': 'Irak',
    'IE': 'Irlanda',
    'IM': 'Isla de Man',
    'IL': 'Israel',
    'IT': 'Italia',
    'JM': 'Jamaica',
    'JP': 'Japón',
    'JE': 'Jersey',
    'JO': 'Jordania',
    'KZ': 'Kazajistán',
    'KE': 'Kenia',
    'KI': 'Kiribati',
    'KP': 'Corea del Norte',
    'KR': 'Corea del Sur',
    'KW': 'Kuwait',
    'KG': 'Kirguistán',
    'LA': 'Laos',
    'LV': 'Letonia',
    'LB': 'Líbano',
    'LS': 'Lesoto',
    'LR': 'Liberia',
    'LY': 'Libia',
    'LI': 'Liechtenstein',
    'LT': 'Lituania',
    'LU': 'Luxemburgo',
    'MO': 'Macao',
    'MG': 'Madagascar',
    'MW': 'Malaui',
    'MY': 'Malasia',
    'MV': 'Maldivas',
    'ML': 'Malí',
    'MT': 'Malta',
    'MH': 'Islas Marshall',
    'MQ': 'Martinica',
    'MR': 'Mauritania',
    'MU': 'Mauricio',
    'YT': 'Mayotte',
    'MX': 'México',
    'FM': 'Micronesia',
    'MD': 'Moldavia',
    'MC': 'Mónaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Marruecos',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Países Bajos',
    'NC': 'Nueva Caledonia',
    'NZ': 'Nueva Zelanda',
    'NI': 'Nicaragua',
    'NE': 'Níger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Isla Norfolk',
    'MP': 'Islas Marianas del Norte',
    'NO': 'Noruega',
    'OM': 'Omán',
    'PK': 'Pakistán',
    'PW': 'Palaos',
    'PS': 'Palestina',
    'PA': 'Panamá',
    'PG': 'Papúa Nueva Guinea',
    'PY': 'Paraguay',
    'PE': 'Perú',
    'PH': 'Filipinas',
    'PN': 'Islas Pitcairn',
    'PL': 'Polonia',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Catar',
    'MK': 'República de Macedonia del Norte',
    'RO': 'Rumania',
    'RU': 'Federación Rusa',
    'RW': 'Ruanda',
    'RE': 'Reunión',
    'BL': 'San Bartolomé',
    'SH': 'Santa Elena, Ascensión y Tristán de Acuña',
    'KN': 'San Cristóbal y Nieves',
    'LC': 'Santa Lucía',
    'MF': 'San Martín',
    'PM': 'San Pedro y Miquelón',
    'VC': 'San Vicente y las Granadinas',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Santo Tomé y Príncipe',
    'SA': 'Arabia Saudita',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leona',
    'SG': 'Singapur',
    'SX': 'Sint Maarten',
    'SK': 'Eslovaquia',
    'SI': 'Eslovenia',
    'SB': 'Islas Salomón',
    'SO': 'Somalia',
    'ZA': 'Sudáfrica',
    'GS': 'Georgia del Sur y las Islas Sandwich del Sur',
    'SS': 'Sudán del Sur',
    'ES': 'España',
    'LK': 'Sri Lanka',
    'SD': 'Sudán',
    'SR': 'Surinam',
    'SJ': 'Svalbard y Jan Mayen',
    'SE': 'Suecia',
    'CH': 'Suiza',
    'SY': 'República Árabe Siria',
    'TW': 'Taiwán',
    'TJ': 'Tayikistán',
    'TZ': 'Tanzania, República Unida de',
    'TH': 'Tailandia',
    'TL': 'Timor-Leste',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad y Tobago',
    'TN': 'Túnez',
    'TR': 'Turquía',
    'TM': 'Turkmenistán',
    'TC': 'Islas Turcas y Caicos',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ucrania',
    'AE': 'Emiratos Árabes Unidos',
    'GB': 'Reino Unido de Gran Bretaña e Irlanda del Norte',
    'UM': 'Islas Ultramarinas Menores de Estados Unidos',
    'US': 'Estados Unidos de América',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistán',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VG': 'Islas Vírgenes Británicas',
    'VI': 'Islas Vírgenes de los Estados Unidos',
    'WF': 'Wallis y Futuna',
    'EH': 'Sahara Occidental',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabue',
    'AX': 'Islas Åland'
  };

  public countryListAlpha2Italian = {
    'AF': 'Afghanistan',
    'AL': 'Albania',
    'DZ': 'Algeria',
    'AS': 'Samoa Americane',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antartide',
    'AG': 'Antigua e Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaigian',
    'BS': 'Bahamas',
    'BH': 'Bahrein',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Bielorussia',
    'BE': 'Belgio',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivia',
    'BQ': 'Caraibi Olandesi',
    'BA': 'Bosnia ed Erzegovina',
    'BW': 'Botswana',
    'BV': 'Isola Bouvet',
    'BR': 'Brasile',
    'IO': 'Territorio Britannico dell Oceano Indiano',
    'BN': 'Brunei',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Capo Verde',
    'KH': 'Cambogia',
    'CM': 'Camerun',
    'CA': 'Canada',
    'KY': 'Isole Cayman',
    'CF': 'Repubblica Centrafricana',
    'TD': 'Ciad',
    'CL': 'Cile',
    'CN': 'Cina',
    'CX': 'Isola di Natale',
    'CC': 'Isole Cocos',
    'CO': 'Colombia',
    'KM': 'Comore',
    'CD': 'Repubblica Democratica del Congo',
    'CG': 'Congo',
    'CK': 'Isole Cook',
    'CR': 'Costa Rica',
    'HR': 'Croazia',
    'CU': 'Cuba',
    'CW': 'Curaçao',
    'CY': 'Cipro',
    'CZ': 'Repubblica Ceca',
    'CI': 'Costa d\'Avorio',
    'DK': 'Danimarca',
    'DJ': 'Gibuti',
    'DM': 'Dominica',
    'DO': 'Repubblica Dominicana',
    'EC': 'Ecuador',
    'EG': 'Egitto',
    'SV': 'El Salvador',
    'GQ': 'Guinea Equatoriale',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'SZ': 'eSwatini',
    'ET': 'Etiopia',
    'FK': 'Isole Falkland',
    'FO': 'Isole Fær Øer',
    'FJ': 'Figi',
    'FI': 'Finlandia',
    'FR': 'Francia',
    'GF': 'Guyana Francese',
    'PF': 'Polinesia Francese',
    'TF': 'Terre Australi e Antartiche Francesi',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Germania',
    'GH': 'Ghana',
    'GI': 'Gibilterra',
    'GR': 'Grecia',
    'GL': 'Groenlandia',
    'GD': 'Grenada',
    'GP': 'Guadalupa',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Isole Heard e McDonald',
    'VA': 'Città del Vaticano',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Ungheria',
    'IS': 'Islanda',
    'IN': 'India',
    'ID': 'Indonesia',
    'IR': 'Iran',
    'IQ': 'Iraq',
    'IE': 'Irlanda',
    'IM': 'Isola di Man',
    'IL': 'Israele',
    'IT': 'Italia',
    'JM': 'Giamaica',
    'JP': 'Giappone',
    'JE': 'Jersey',
    'JO': 'Giordania',
    'KZ': 'Kazakistan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KP': 'Corea del Nord',
    'KR': 'Corea del Sud',
    'KW': 'Kuwait',
    'KG': 'Kirghizistan',
    'LA': 'Laos',
    'LV': 'Lettonia',
    'LB': 'Libano',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libia',
    'LI': 'Liechtenstein',
    'LT': 'Lituania',
    'LU': 'Lussemburgo',
    'MO': 'Macao',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Maldive',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Isole Marshall',
    'MQ': 'Martinica',
    'MR': 'Mauritania',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Messico',
    'FM': 'Stati Federati di Micronesia',
    'MD': 'Moldavia',
    'MC': 'Monaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Marocco',
    'MZ': 'Mozambico',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Paesi Bassi',
    'NC': 'Nuova Caledonia',
    'NZ': 'Nuova Zelanda',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Isola Norfolk',
    'MP': 'Isole Marianne Settentrionali',
    'NO': 'Norvegia',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestina',
    'PA': 'Panama',
    'PG': 'Papua Nuova Guinea',
    'PY': 'Paraguay',
    'PE': 'Perù',
    'PH': 'Filippine',
    'PN': 'Isole Pitcairn',
    'PL': 'Polonia',
    'PT': 'Portogallo',
    'PR': 'Porto Rico',
    'QA': 'Qatar',
    'MK': 'Macedonia del Nord',
    'RO': 'Romania',
    'RU': 'Russia',
    'RW': 'Ruanda',
    'RE': 'Réunion',
    'BL': 'Saint-Barthélemy',
    'SH': 'Sant Elena',
    'KN': 'Saint Kitts e Nevis',
    'LC': 'Santa Lucia',
    'MF': 'Saint Martin',
    'PM': 'Saint Pierre e Miquelon',
    'VC': 'Saint Vincent e Grenadine',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'São Tomé e Príncipe',
    'SA': 'Arabia Saudita',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SX': 'Sint Maarten',
    'SK': 'Slovacchia',
    'SI': 'Slovenia',
    'SB': 'Isole Salomone',
    'SO': 'Somalia',
    'ZA': 'Sudafrica',
    'GS': 'Georgia del Sud e Isole Sandwich Meridionali',
    'SS': 'Sudan del Sud',
    'ES': 'Spagna',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard e Jan Mayen',
    'SE': 'Svezia',
    'CH': 'Svizzera',
    'SY': 'Siria',
    'TW': 'Taiwan',
    'TJ': 'Tagikistan',
    'TZ': 'Tanzania',
    'TH': 'Tailandia',
    'TL': 'Timor Est',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad e Tobago',
    'TN': 'Tunisia',
    'TR': 'Turchia',
    'TM': 'Turkmenistan',
    'TC': 'Isole Turks e Caicos',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ucraina',
    'AE': 'Emirati Arabi Uniti',
    'GB': 'Regno Unito',
    'UM': 'Piccole Isole esterne degli Stati Uniti',
    'US': 'Stati Uniti',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VG': 'Isole Vergini Britanniche',
    'VI': 'Isole Vergini Americane',
    'WF': 'Wallis e Futuna',
    'EH': 'Sahara Occidentale',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe',
    'AX': 'Isole Åland'
  };

  public countryListAlpha2Polish = {
    'AF': 'Afganistan',
    'AL': 'Albania',
    'DZ': 'Algieria',
    'AS': 'Samoa Amerykańskie',
    'AD': 'Andora',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarktyka',
    'AG': 'Antigua i Barbuda',
    'AR': 'Argentyna',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbejdżan',
    'BS': 'Bahamy',
    'BH': 'Bahrajn',
    'BD': 'Bangladesz',
    'BB': 'Barbados',
    'BY': 'Białoruś',
    'BE': 'Belgia',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermudy',
    'BT': 'Bhutan',
    'BO': 'Boliwia',
    'BQ': 'Bonaire, Sint Eustatius i Saba',
    'BA': 'Bośnia i Hercegowina',
    'BW': 'Botswana',
    'BV': 'Wyspa Bouveta',
    'BR': 'Brazylia',
    'IO': 'Brytyjskie Terytorium Oceanu Indyjskiego',
    'BN': 'Brunei',
    'BG': 'Bułgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Republika Zielonego Przylądka',
    'KH': 'Kambodża',
    'CM': 'Kamerun',
    'CA': 'Kanada',
    'KY': 'Kajmany',
    'CF': 'Republika Środkowoafrykańska',
    'TD': 'Czad',
    'CL': 'Chile',
    'CN': 'Chiny',
    'CX': 'Wyspa Bożego Narodzenia',
    'CC': 'Wyspy Kokosowe',
    'CO': 'Kolumbia',
    'KM': 'Komory',
    'CD': 'Demokratyczna Republika Konga',
    'CG': 'Kongo',
    'CK': 'Wyspy Cooka',
    'CR': 'Kostaryka',
    'HR': 'Chorwacja',
    'CU': 'Kuba',
    'CW': 'Curaçao',
    'CY': 'Cypr',
    'CZ': 'Czechy',
    'CI': 'Wybrzeże Kości Słoniowej',
    'DK': 'Dania',
    'DJ': 'Dżibuti',
    'DM': 'Dominika',
    'DO': 'Dominikana',
    'EC': 'Ekwador',
    'EG': 'Egipt',
    'SV': 'Salwador',
    'GQ': 'Gwinea Równikowa',
    'ER': 'Erytrea',
    'EE': 'Estonia',
    'SZ': 'Eswatini',
    'ET': 'Etiopia',
    'FK': 'Falklandy',
    'FO': 'Wyspy Owcze',
    'FJ': 'Fidżi',
    'FI': 'Finlandia',
    'FR': 'Francja',
    'GF': 'Gujana Francuska',
    'PF': 'Polinezja Francuska',
    'TF': 'Francuskie Terytoria Południowe i Antarktyczne',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Gruzja',
    'DE': 'Niemcy',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Grecja',
    'GL': 'Grenlandia',
    'GD': 'Grenada',
    'GP': 'Gwadelupa',
    'GU': 'Guam',
    'GT': 'Gwatemala',
    'GG': 'Guernsey',
    'GN': 'Gwinea',
    'GW': 'Gwinea Bissau',
    'GY': 'Gujana',
    'HT': 'Haiti',
    'HM': 'Wyspy Heard i McDonalda',
    'VA': 'Watykan',
    'HN': 'Honduras',
    'HK': 'Hongkong',
    'HU': 'Węgry',
    'IS': 'Islandia',
    'IN': 'Indie',
    'ID': 'Indonezja',
    'IR': 'Iran',
    'IQ': 'Irak',
    'IE': 'Irlandia',
    'IM': 'Wyspa Man',
    'IL': 'Izrael',
    'IT': 'Włochy',
    'JM': 'Jamajka',
    'JP': 'Japonia',
    'JE': 'Jersey',
    'JO': 'Jordania',
    'KZ': 'Kazachstan',
    'KE': 'Kenia',
    'KI': 'Kiribati',
    'KP': 'Korea Północna',
    'KR': 'Korea Południowa',
    'KW': 'Kuwejt',
    'KG': 'Kirgistan',
    'LA': 'Laos',
    'LV': 'Łotwa',
    'LB': 'Liban',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libia',
    'LI': 'Liechtenstein',
    'LT': 'Litwa',
    'LU': 'Luksemburg',
    'MO': 'Makau',
    'MG': 'Madagaskar',
    'MW': 'Malawi',
    'MY': 'Malezja',
    'MV': 'Malediwy',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Wyspy Marshalla',
    'MQ': 'Martynika',
    'MR': 'Mauretania',
    'MU': 'Mauritius',
    'YT': 'Majotta',
    'MX': 'Meksyk',
    'FM': 'Mikronezja',
    'MD': 'Mołdawia',
    'MC': 'Monako',
    'MN': 'Mongolia',
    'ME': 'Czarnogóra',
    'MS': 'Montserrat',
    'MA': 'Maroko',
    'MZ': 'Mozambik',
    'MM': 'Mjanma',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Holandia',
    'NC': 'Nowa Kaledonia',
    'NZ': 'Nowa Zelandia',
    'NI': 'Nikaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Wyspa Norfolk',
    'MP': 'Mariany Północne',
    'NO': 'Norwegia',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestyna',
    'PA': 'Panama',
    'PG': 'Papua-Nowa Gwinea',
    'PY': 'Paragwaj',
    'PE': 'Peru',
    'PH': 'Filipiny',
    'PN': 'Pitcairn',
    'PL': 'Polska',
    'PT': 'Portugalia',
    'PR': 'Portoryko',
    'QA': 'Katar',
    'MK': 'Macedonia Północna',
    'RO': 'Rumunia',
    'RU': 'Rosja',
    'RW': 'Rwanda',
    'RE': 'Reunion',
    'BL': 'Saint-Barthélemy',
    'SH': 'Wyspa Świętej Heleny',
    'KN': 'Saint Kitts i Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint-Martin',
    'PM': 'Saint-Pierre i Miquelon',
    'VC': 'Saint Vincent i Grenadyny',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Wyspy Świętego Tomasza i Książęca',
    'SA': 'Arabia Saudyjska',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seszele',
    'SL': 'Sierra Leone',
    'SG': 'Singapur',
    'SX': 'Sint Maarten',
    'SK': 'Słowacja',
    'SI': 'Słowenia',
    'SB': 'Wyspy Salomona',
    'SO': 'Somalia',
    'ZA': 'Republika Południowej Afryki',
    'GS': 'Georgia Południowa i Sandwich Południowy',
    'SS': 'Sudan Południowy',
    'ES': 'Hiszpania',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Surinam',
    'SJ': 'Svalbard i Jan Mayen',
    'SE': 'Szwecja',
    'CH': 'Szwajcaria',
    'SY': 'Syria',
    'TW': 'Tajwan',
    'TJ': 'Tadżykistan',
    'TZ': 'Tanzania',
    'TH': 'Tajlandia',
    'TL': 'Timor Wschodni',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trynidad i Tobago',
    'TN': 'Tunezja',
    'TR': 'Turcja',
    'TM': 'Turkmenistan',
    'TC': 'Turks i Caicos',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraina',
    'AE': 'Zjednoczone Emiraty Arabskie',
    'GB': 'Wielka Brytania',
    'UM': 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
    'US': 'Stany Zjednoczone',
    'UY': 'Urugwaj',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Wenezuela',
    'VN': 'Wietnam',
    'VG': 'Brytyjskie Wyspy Dziewicze',
    'VI': 'Amerykańskie Wyspy Dziewicze',
    'WF': 'Wallis i Futuna',
    'EH': 'Sahara Zachodnia',
    'YE': 'Jemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe',
    'AX': 'Wyspy Alandzkie'
  };

  public countryListAlpha2French = {
    'AF': 'Afghanistan',
    'AL': 'Albanie',
    'DZ': 'Algérie',
    'AS': 'Samoa américaines',
    'AD': 'Andorre',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctique',
    'AG': 'Antigua-et-Barbuda',
    'AR': 'Argentine',
    'AM': 'Arménie',
    'AW': 'Aruba',
    'AU': 'Australie',
    'AT': 'Autriche',
    'AZ': 'Azerbaïdjan',
    'BS': 'Bahamas',
    'BH': 'Bahreïn',
    'BD': 'Bangladesh',
    'BB': 'Barbade',
    'BY': 'Biélorussie',
    'BE': 'Belgique',
    'BZ': 'Belize',
    'BJ': 'Bénin',
    'BM': 'Bermudes',
    'BT': 'Bhoutan',
    'BO': 'Bolivie',
    'BQ': 'Pays-Bas caribéens',
    'BA': 'Bosnie-Herzégovine',
    'BW': 'Botswana',
    'BV': 'Île Bouvet',
    'BR': 'Brésil',
    'IO': 'Territoire britannique de l\'océan Indien',
    'BN': 'Brunei',
    'BG': 'Bulgarie',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Cap-Vert',
    'KH': 'Cambodge',
    'CM': 'Cameroun',
    'CA': 'Canada',
    'KY': 'Îles Caïmans',
    'CF': 'République centrafricaine',
    'TD': 'Tchad',
    'CL': 'Chili',
    'CN': 'Chine',
    'CX': 'Île Christmas',
    'CC': 'Îles Cocos',
    'CO': 'Colombie',
    'KM': 'Comores',
    'CD': 'République démocratique du Congo',
    'CG': 'Congo',
    'CK': 'Îles Cook',
    'CR': 'Costa Rica',
    'HR': 'Croatie',
    'CU': 'Cuba',
    'CW': 'Curaçao',
    'CY': 'Chypre',
    'CZ': 'Tchéquie',
    'CI': 'Côte d\'Ivoire',
    'DK': 'Danemark',
    'DJ': 'Djibouti',
    'DM': 'Dominique',
    'DO': 'République dominicaine',
    'EC': 'Équateur',
    'EG': 'Égypte',
    'SV': 'Salvador',
    'GQ': 'Guinée équatoriale',
    'ER': 'Érythrée',
    'EE': 'Estonie',
    'SZ': 'Eswatini',
    'ET': 'Éthiopie',
    'FK': 'Îles Malouines',
    'FO': 'Îles Féroé',
    'FJ': 'Fidji',
    'FI': 'Finlande',
    'FR': 'France',
    'GF': 'Guyane française',
    'PF': 'Polynésie française',
    'TF': 'Terres australes et antarctiques françaises',
    'GA': 'Gabon',
    'GM': 'Gambie',
    'GE': 'Géorgie',
    'DE': 'Allemagne',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Grèce',
    'GL': 'Groenland',
    'GD': 'Grenade',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernesey',
    'GN': 'Guinée',
    'GW': 'Guinée-Bissau',
    'GY': 'Guyana',
    'HT': 'Haïti',
    'HM': 'Îles Heard et McDonald',
    'VA': 'Vatican',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hongrie',
    'IS': 'Islande',
    'IN': 'Inde',
    'ID': 'Indonésie',
    'IR': 'Iran',
    'IQ': 'Irak',
    'IE': 'Irlande',
    'IM': 'Île de Man',
    'IL': 'Israël',
    'IT': 'Italie',
    'JM': 'Jamaïque',
    'JP': 'Japon',
    'JE': 'Jersey',
    'JO': 'Jordanie',
    'KZ': 'Kazakhstan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KP': 'Corée du Nord',
    'KR': 'Corée du Sud',
    'KW': 'Koweït',
    'KG': 'Kirghizistan',
    'LA': 'Laos',
    'LV': 'Lettonie',
    'LB': 'Liban',
    'LS': 'Lesotho',
    'LR': 'Libéria',
    'LY': 'Libye',
    'LI': 'Liechtenstein',
    'LT': 'Lituanie',
    'LU': 'Luxembourg',
    'MO': 'Macao',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaisie',
    'MV': 'Maldives',
    'ML': 'Mali',
    'MT': 'Malte',
    'MH': 'Îles Marshall',
    'MQ': 'Martinique',
    'MR': 'Mauritanie',
    'MU': 'Maurice',
    'YT': 'Mayotte',
    'MX': 'Mexique',
    'FM': 'Micronésie',
    'MD': 'Moldavie',
    'MC': 'Monaco',
    'MN': 'Mongolie',
    'ME': 'Monténégro',
    'MS': 'Montserrat',
    'MA': 'Maroc',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibie',
    'NR': 'Nauru',
    'NP': 'Népal',
    'NL': 'Pays-Bas',
    'NC': 'Nouvelle-Calédonie',
    'NZ': 'Nouvelle-Zélande',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigéria',
    'NU': 'Niue',
    'NF': 'Île Norfolk',
    'MP': 'Îles Mariannes du Nord',
    'NO': 'Norvège',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palaos',
    'PS': 'Palestine',
    'PA': 'Panama',
    'PG': 'Papouasie-Nouvelle-Guinée',
    'PY': 'Paraguay',
    'PE': 'Pérou',
    'PH': 'Philippines',
    'PN': 'Îles Pitcairn',
    'PL': 'Pologne',
    'PT': 'Portugal',
    'PR': 'Porto Rico',
    'QA': 'Qatar',
    'MK': 'Macédoine du Nord',
    'RO': 'Roumanie',
    'RU': 'Russie',
    'RW': 'Rwanda',
    'RE': 'La Réunion',
    'BL': 'Saint-Barthélemy',
    'SH': 'Sainte-Hélène',
    'KN': 'Saint-Kitts-et-Nevis',
    'LC': 'Sainte-Lucie',
    'MF': 'Saint-Martin',
    'PM': 'Saint-Pierre-et-Miquelon',
    'VC': 'Saint-Vincent-et-les-Grenadines',
    'WS': 'Samoa',
    'SM': 'Saint-Marin',
    'ST': 'Sao Tomé-et-Principe',
    'SA': 'Arabie saoudite',
    'SN': 'Sénégal',
    'RS': 'Serbie',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapour',
    'SX': 'Saint-Martin (partie néerlandaise)',
    'SK': 'Slovaquie',
    'SI': 'Slovénie',
    'SB': 'Îles Salomon',
    'SO': 'Somalie',
    'ZA': 'Afrique du Sud',
    'GS': 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'SS': 'Soudan du Sud',
    'ES': 'Espagne',
    'LK': 'Sri Lanka',
    'SD': 'Soudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard et Jan Mayen',
    'SE': 'Suède',
    'CH': 'Suisse',
    'SY': 'Syrie',
    'TW': 'Taïwan',
    'TJ': 'Tadjikistan',
    'TZ': 'Tanzanie',
    'TH': 'Thaïlande',
    'TL': 'Timor oriental',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinité-et-Tobago',
    'TN': 'Tunisie',
    'TR': 'Turquie',
    'TM': 'Turkménistan',
    'TC': 'Îles Turques-et-Caïques',
    'TV': 'Tuvalu',
    'UG': 'Ouganda',
    'UA': 'Ukraine',
    'AE': 'Émirats arabes unis',
    'GB': 'Royaume-Uni',
    'UM': 'Îles mineures éloignées des États-Unis',
    'US': 'États-Unis',
    'UY': 'Uruguay',
    'UZ': 'Ouzbékistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VG': 'Îles Vierges britanniques',
    'VI': 'Îles Vierges des États-Unis',
    'WF': 'Wallis-et-Futuna',
    'EH': 'Sahara occidental',
    'YE': 'Yémen',
    'ZM': 'Zambie',
    'ZW': 'Zimbabwe',
    'AX': 'Åland'
  };

  public countryListAlpha2Dutch = {
    'AF': 'Afghanistan',
    'AL': 'Albanië',
    'DZ': 'Algerije',
    'AS': 'Amerikaans-Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua en Barbuda',
    'AR': 'Argentinië',
    'AM': 'Armenië',
    'AW': 'Aruba',
    'AU': 'Australië',
    'AT': 'Oostenrijk',
    'AZ': 'Azerbeidzjan',
    'BS': 'Bahama\'s',
    'BH': 'Bahrein',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Wit-Rusland',
    'BE': 'België',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivië',
    'BQ': 'Caribisch Nederland',
    'BA': 'Bosnië en Herzegovina',
    'BW': 'Botswana',
    'BV': 'Bouvet',
    'BR': 'Brazilië',
    'IO': 'Brits Indische Oceaanterritorium',
    'BN': 'Brunei',
    'BG': 'Bulgarije',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Kaapverdië',
    'KH': 'Cambodja',
    'CM': 'Kameroen',
    'CA': 'Canada',
    'KY': 'Kaaimaneilanden',
    'CF': 'Centraal-Afrikaanse Republiek',
    'TD': 'Tsjaad',
    'CL': 'Chili',
    'CN': 'China',
    'CX': 'Christmaseiland',
    'CC': 'Cocoseilanden',
    'CO': 'Colombia',
    'KM': 'Comoren',
    'CD': 'Democratische Republiek Congo',
    'CG': 'Congo',
    'CK': 'Cookeilanden',
    'CR': 'Costa Rica',
    'HR': 'Kroatië',
    'CU': 'Cuba',
    'CW': 'Curaçao',
    'CY': 'Cyprus',
    'CZ': 'Tsjechië',
    'CI': 'Ivoorkust',
    'DK': 'Denemarken',
    'DJ': 'Djibouti',
    'DM': 'Dominica',
    'DO': 'Dominicaanse Republiek',
    'EC': 'Ecuador',
    'EG': 'Egypte',
    'SV': 'El Salvador',
    'GQ': 'Equatoriaal-Guinea',
    'ER': 'Eritrea',
    'EE': 'Estland',
    'SZ': 'Eswatini',
    'ET': 'Ethiopië',
    'FK': 'Falklandeilanden',
    'FO': 'Faeröer',
    'FJ': 'Fiji',
    'FI': 'Finland',
    'FR': 'Frankrijk',
    'GF': 'Frans-Guyana',
    'PF': 'Frans-Polynesië',
    'TF': 'Franse Zuidelijke en Antarctische Gebieden',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgië',
    'DE': 'Duitsland',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Griekenland',
    'GL': 'Groenland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinee',
    'GW': 'Guinee-Bissau',
    'GY': 'Guyana',
    'HT': 'Haïti',
    'HM': 'Heard- en McDonaldeilanden',
    'VA': 'Vaticaanstad',
    'HN': 'Honduras',
    'HK': 'Hongkong',
    'HU': 'Hongarije',
    'IS': 'IJsland',
    'IN': 'India',
    'ID': 'Indonesië',
    'IR': 'Iran',
    'IQ': 'Irak',
    'IE': 'Ierland',
    'IM': 'Isle of Man',
    'IL': 'Israël',
    'IT': 'Italië',
    'JM': 'Jamaica',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordanië',
    'KZ': 'Kazachstan',
    'KE': 'Kenia',
    'KI': 'Kiribati',
    'KP': 'Noord-Korea',
    'KR': 'Zuid-Korea',
    'KW': 'Koeweit',
    'KG': 'Kirgizië',
    'LA': 'Laos',
    'LV': 'Letland',
    'LB': 'Libanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libië',
    'LI': 'Liechtenstein',
    'LT': 'Litouwen',
    'LU': 'Luxemburg',
    'MO': 'Macao',
    'MG': 'Madagaskar',
    'MW': 'Malawi',
    'MY': 'Maleisië',
    'MV': 'Maldiven',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshalleilanden',
    'MQ': 'Martinique',
    'MR': 'Mauritanië',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexico',
    'FM': 'Micronesië',
    'MD': 'Moldavië',
    'MC': 'Monaco',
    'MN': 'Mongolië',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Marokko',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibië',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Nederland',
    'NC': 'Nieuw-Caledonië',
    'NZ': 'Nieuw-Zeeland',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolk',
    'MP': 'Noordelijke Marianen',
    'NO': 'Noorwegen',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestina',
    'PA': 'Panama',
    'PG': 'Papoea-Nieuw-Guinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Filipijnen',
    'PN': 'Pitcairneilanden',
    'PL': 'Polen',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'MK': 'Noord-Macedonië',
    'RO': 'Roemenië',
    'RU': 'Rusland',
    'RW': 'Rwanda',
    'RE': 'Réunion',
    'BL': 'Saint-Barthélemy',
    'SH': 'Sint-Helena',
    'KN': 'Saint Kitts en Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint-Martin',
    'PM': 'Saint-Pierre en Miquelon',
    'VC': 'Saint Vincent en de Grenadines',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tomé en Principe',
    'SA': 'Saoedi-Arabië',
    'SN': 'Senegal',
    'RS': 'Servië',
    'SC': 'Seychellen',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SX': 'Sint Maarten',
    'SK': 'Slowakije',
    'SI': 'Slovenië',
    'SB': 'Salomonseilanden',
    'SO': 'Somalië',
    'ZA': 'Zuid-Afrika',
    'GS': 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    'SS': 'Zuid-Soedan',
    'ES': 'Spanje',
    'LK': 'Sri Lanka',
    'SD': 'Soedan',
    'SR': 'Suriname',
    'SJ': 'Spitsbergen en Jan Mayen',
    'SE': 'Zweden',
    'CH': 'Zwitserland',
    'SY': 'Syrië',
    'TW': 'Taiwan',
    'TJ': 'Tadzjikistan',
    'TZ': 'Tanzania',
    'TH': 'Thailand',
    'TL': 'Oost-Timor',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad en Tobago',
    'TN': 'Tunesië',
    'TR': 'Turkije',
    'TM': 'Turkmenistan',
    'TC': 'Turks- en Caicoseilanden',
    'TV': 'Tuvalu',
    'UG': 'Oeganda',
    'UA': 'Oekraïne',
    'AE': 'Verenigde Arabische Emiraten',
    'GB': 'Verenigd Koninkrijk',
    'UM': 'Kleine afgelegen eilanden van de Verenigde Staten',
    'US': 'Verenigde Staten',
    'UY': 'Uruguay',
    'UZ': 'Oezbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VG': 'Britse Maagdeneilanden',
    'VI': 'Amerikaanse Maagdeneilanden',
    'WF': 'Wallis en Futuna',
    'EH': 'Westelijke Sahara',
    'YE': 'Jemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe',
    'AX': 'Åland'
  };

  public getAllCountryCodes() {
    const codes = [];
    for (const countryCode in this.countryListAlpha2German) {
      if (this.countryListAlpha2English.hasOwnProperty(countryCode)) {
        codes.push(countryCode);
      }
    }

    return codes;
  }

  public getAllGermanCountryDataAsArray() {
    const codes = [];
    for (const countryCode in this.countryListAlpha2German) {
      if (this.countryListAlpha2English.hasOwnProperty(countryCode)) {
        codes.push({countryCode, name: this.countryListAlpha2German[countryCode]});
      }
    }

    return codes;
  }

  public getAllEnglishCountryDataAsArray(): Array<{ countryCode: string, name: string }> {
    const codes = [];
    for (const countryCode in this.countryListAlpha2English) {
      if (this.countryListAlpha2English.hasOwnProperty(countryCode)) {
        codes.push({countryCode, name: this.countryListAlpha2English[countryCode]});
      }
    }

    return codes;
  }


}

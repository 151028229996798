import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';
import { CountryCodeService } from '../../../../services/country-code/country-code.service';
import { FieldsBasicPlaceDetails } from '../../../../shared/consts/fields-basic-place-details';
import { FormUtils } from '../../../../shared/utils/form-utils';
import { EventType, GroupTypeFrontend } from '@tripbakers/enums';

@Component({
  selector: 'tripmakery-bl-keyword-add-bl-keyword-modal',
  templateUrl: './bl-keyword-add-bl-keyword-modal.component.html',
})
export class BlKeywordAddBlKeywordModalComponent implements OnInit {

  public destinationGooglePlaceDetails;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };

  public bookingChangeRequestId: string;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  public bsConfig = {
    ignoreBackdropClick: true,
    backdrop: true,
  };

  public isGooglePlaceIdInUse: boolean;
  public isNameSimilar: boolean;
  public doesBoundaryExist: boolean;
  public isBoundaryApproved: boolean;
  public urlBoundaryApproval: string;
  public urlBoundaryAdd: string;

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;
  public googlePlaceIdInUseUrl: string;
  public sortingAlgorithms: string[] = [];
  public preferredGroupTypes = Object.values(GroupTypeFrontend);
  public preferredEventTypes = Object.values(EventType);

  public countryListEnglish: Array<{ countryCode: string, name: string }>;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router,
              public countryCodeService: CountryCodeService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.form = new FormGroup({
      'keyword': new FormControl({value: '', disabled: false}),

      'slugsKeywordDe': new FormControl({value: '', disabled: false}),
      'slugsKeywordEn': new FormControl({value: '', disabled: false}),
      'slugsKeywordEnGb': new FormControl({value: '', disabled: false}),
      'slugsKeywordEs': new FormControl({value: '', disabled: false}),
      'slugsKeywordIt': new FormControl({value: '', disabled: false}),
      'slugsKeywordEnAu': new FormControl({value: '', disabled: false}),
      'slugsKeywordPl': new FormControl({value: '', disabled: false}),
      'slugsKeywordFr': new FormControl({value: '', disabled: false}),
      'slugsKeywordNl': new FormControl({value: '', disabled: false}),

      'keywordNameDe': new FormControl({value: '', disabled: false}),
      'keywordNameEn': new FormControl({value: '', disabled: false}),
      'keywordNameEnGb': new FormControl({value: '', disabled: false}),
      'keywordNameEs': new FormControl({value: '', disabled: false}),
      'keywordNameIt': new FormControl({value: '', disabled: false}),
      'keywordNameEnAu': new FormControl({value: '', disabled: false}),
      'keywordNamePl': new FormControl({value: '', disabled: false}),
      'keywordNameFr': new FormControl({value: '', disabled: false}),
      'keywordNameNl': new FormControl({value: '', disabled: false}),

      'keywordNamePluralDe': new FormControl({value: '', disabled: false}),
      'keywordNamePluralEn': new FormControl({value: '', disabled: false}),
      'keywordNamePluralEnGb': new FormControl({value: '', disabled: false}),
      'keywordNamePluralEs': new FormControl({value: '', disabled: false}),
      'keywordNamePluralIt': new FormControl({value: '', disabled: false}),
      'keywordNamePluralEnAu': new FormControl({value: '', disabled: false}),
      'keywordNamePluralPl': new FormControl({value: '', disabled: false}),
      'keywordNamePluralFr': new FormControl({value: '', disabled: false}),
      'keywordNamePluralNl': new FormControl({value: '', disabled: false}),

      'housingSortingAlgorithm': new FormControl({value: '', disabled: false}),
      'keywordCategory': new FormControl({value: 0, disabled: false}),
      'isEnabled': new FormControl({value: false, disabled: false}),

      'preferredGroupType': new FormControl({value: '', disabled: false}),
      'preferredEventType': new FormControl({value: '', disabled: false}),

    });
    this.environment_ = environment;
  }

  public ngOnInit() {
    this.form.get('keywordNameDe').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordDe').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameEn').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordEn').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameEnGb').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordEnGb').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameEs').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordEs').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameIt').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordIt').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameEnAu').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordEnAu').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNamePl').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordPl').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameFr').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordFr').patchValue(this.createSlugFromString(value));
    });
    this.form.get('keywordNameNl').valueChanges.subscribe(value => {
      this.form.get('slugsKeywordNl').patchValue(this.createSlugFromString(value));
    });
    this.handleReceiveSortingAlgorithms();
  }

  public showChildModal(): void {
    this.isEmailInUse = false;
    this.form.reset();

    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    /* */
    const body = this.createCreationBody();

    console.log(body);

    /* */
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/pages/bl-keyword', body)
      .subscribe(
        (response: any) => {
          this.router.navigate(['pages', 'bl-keyword', response.pageBlKeyword._id]);

        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            for (const error of err.error.errors) {
              try {
                console.log(error.property, error.constraints);

                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

              } catch (error) {
                console.log('Catched E', error);
              }
            }

            this.hideLoading();
            return;
          } else if (err.error.message === 'E_PAGE_DESTINATION_GOOGLE_PLACE_ID_ALREADY_IN_USE') { // TODO
            this.isGooglePlaceIdInUse = true;
            this.googlePlaceIdInUseUrl = environment.urls.tool + '/pages/destinations/' + err.error.pageDestinationId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
          this.hideChildModal();
        }
      );
  }

  public handleReceiveSortingAlgorithms() {
    /* */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/bl-keyword/sorting-algorithms')
      .subscribe(
        (response: any) => {
          this.sortingAlgorithms = response.sortingAlgorithms;
        },
        (err) => {
          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  private createCreationBody() {

    return {
      /* */
      keyword: FormUtils.createNullIfEmptyString(this.form.get('keyword').value),

      slugsKeywordDe: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordDe').value),
      slugsKeywordEn: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEn').value),
      slugsKeywordEnGb: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEnGb').value),
      slugsKeywordEs: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEs').value),
      slugsKeywordIt: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordIt').value),
      slugsKeywordEnAu: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordEnAu').value),
      slugsKeywordPl: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordPl').value),
      slugsKeywordFr: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordFr').value),
      slugsKeywordNl: FormUtils.createNullIfEmptyString(this.form.get('slugsKeywordNl').value),

      keywordNameDe: FormUtils.createNullIfEmptyString(this.form.get('keywordNameDe').value),
      keywordNameEn: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEn').value),
      keywordNameEnGb: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEnGb').value),
      keywordNameEs: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEs').value),
      keywordNameIt: FormUtils.createNullIfEmptyString(this.form.get('keywordNameIt').value),
      keywordNameEnAu: FormUtils.createNullIfEmptyString(this.form.get('keywordNameEnAu').value),
      keywordNamePl: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePl').value),
      keywordNameFr: FormUtils.createNullIfEmptyString(this.form.get('keywordNameFr').value),
      keywordNameNl: FormUtils.createNullIfEmptyString(this.form.get('keywordNameNl').value),

      keywordNamePluralDe: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralDe').value),
      keywordNamePluralEn: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEn').value),
      keywordNamePluralEnGb: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEnGb').value),
      keywordNamePluralEs: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEs').value),
      keywordNamePluralIt: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralIt').value),
      keywordNamePluralEnAu: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralEnAu').value),
      keywordNamePluralPl: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralPl').value),
      keywordNamePluralFr: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralFr').value),
      keywordNamePluralNl: FormUtils.createNullIfEmptyString(this.form.get('keywordNamePluralNl').value),

      housingSortingAlgorithm: FormUtils.createNullIfEmptyString(this.form.get('housingSortingAlgorithm').value),
      keywordCategory: FormUtils.returnAsNumberOrNullIfEmptyOrNoNumber(this.form.get('keywordCategory').value),

      isEnabled: FormUtils.createFalseIfNull(this.form.get('isEnabled').value),

      preferredGroupType: FormUtils.createNullIfEmptyString(this.form.get('preferredGroupType').value),
      preferredEventType: FormUtils.createNullIfEmptyString(this.form.get('preferredEventType').value),
    };
  }

  private createSlugFromString(data: string) {
    if (data == null) {
      return '';
    }

    /* make it lowercase */
    let elem = data.toLowerCase();

    /* replace umlauts */
    elem = elem.replace(/\d/g, '').trim();
    elem = elem.replace(/&/g, 'und');
    elem = elem.replace(/ä/g, 'ae');
    elem = elem.replace(/ü/g, 'ue');
    elem = elem.replace(/ö/g, 'oe');

    /* replace other kinds of umlauts */
    elem = elem.replace(/[áàâãå]/g, 'a');
    elem = elem.replace(/[úùû]/g, 'u');
    elem = elem.replace(/[ç]/g, 'c');
    elem = elem.replace(/[ô]/g, 'o');
    elem = elem.replace(/[é]/g, 'e');

    /* replace all non english letters with - */
    elem = elem.replace(/[^A-Za-z0-9\-_]/g, '-');

    /* */
    return elem;
  }

  public extractValidationErrorsAsArray(errors) {
    return FormUtils.extractValidationErrorsAsArray(errors);
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}

<div class="block">
  <div class="block-header block-header-default">
    <h3 class="block-title">Edit User</h3>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary js-tooltip-enabled" data-toggle="tooltip"
              title="" data-original-title="Login as User"
              (click)="getTokenAndForwardToProTool(user._id)">
        <i class="fa fa-user"></i>
      </button>
    </div>
  </div>
  <div class="block-content block-content-full">
    <div class="alert alert-info" *ngIf="isEmailInUse === true">
      <h4>Email Address already in use</h4>
      This email address is already in use by <a href="{{emailInUseUrl}}">this account</a>.
    </div>

    <form [formGroup]="form">
      <div class="row">

        <div class="col-md-12">

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('firstName').dirty === true && form.get('firstName').status === 'INVALID'}">
            <label class="col-12" for="user-add-firstName">First Name</label>
            <div class="col-md-12">
              <input type="text" class="form-control"
                     id="user-add-firstName"
                     name="firstName"
                     placeholder="First Name"
                     autocomplete="off"
                     formControlName="firstName"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('firstName').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('lastName').dirty === true && form.get('lastName').status === 'INVALID'}">
            <label class="col-12" for="user-add-lastName">Last Name</label>
            <div class="col-md-12">
              <input type="text" class="form-control"
                     id="user-add-lastName"
                     name="lastName"
                     placeholder="Last Name"
                     autocomplete="off"
                     formControlName="lastName"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('lastName').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('salutation').dirty === true && form.get('salutation').status === 'INVALID'}">
            <label class="col-12" for="user-add-salutation">Salutation</label>
            <div class="col-md-12">
              <select class="form-control"
                      id="user-add-salutation"
                      formControlName="salutation">
                <option value="">Please select..</option>
                <option value="MR">MR</option>
                <option value="MS">MS</option>
              </select>
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('salutation').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('language').dirty === true && form.get('language').status === 'INVALID'}">
            <label class="col-12" for="user-add-language">Preferred Language</label>
            <div class="col-md-12">
              <select class="form-control"
                      id="user-add-language"
                      formControlName="language">
                <option value="">Please select..</option>
                <option value="de">German</option>
                <option value="en">English</option>
              </select>
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('language').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('email').dirty === true && form.get('email').status === 'INVALID'}">
            <label class="col-12" for="user-add-email">Email</label>
            <div class="col-md-12">
              <input type="text" class="form-control"
                     id="user-add-email"
                     name="email"
                     placeholder="Email"
                     autocomplete="off"
                     formControlName="email"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('email').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('callingCode').dirty === true && form.get('callingCode').status === 'INVALID'}">
            <label class="col-12" for="user-add-callingCode">Calling Code</label>
            <div class="col-md-12">
              <input type="text" class="form-control"
                     id="user-add-callingCode"
                     name="phone"
                     placeholder="Calling Code like +43 or +49"
                     autocomplete="off"
                     formControlName="callingCode"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('callingCode').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('phone').dirty === true && form.get('phone').status === 'INVALID'}">
            <label class="col-12" for="user-add-phone">Phone</label>
            <div class="col-md-12">
              <input type="text" class="form-control"
                     id="user-add-phone"
                     name="phone"
                     placeholder="Phone"
                     autocomplete="off"
                     formControlName="phone"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('phone').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('passwordA').dirty === true && form.get('passwordA').status === 'INVALID'}">
            <label class="col-12" for="user-add-passwordA">Password</label>
            <div class="col-md-12">
              <input type="password" class="form-control"
                     id="user-add-passwordA"
                     name="passwordA"
                     placeholder="Password"
                     autocomplete="off"
                     formControlName="passwordA"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('passwordA').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('passwordB').dirty === true && form.get('passwordB').status === 'INVALID'}">

            <label class="col-12" for="user-add-passwordB">Password</label>
            <div class="col-md-12">
              <input type="password" class="form-control"
                     id="user-add-passwordB"
                     name="passwordB"
                     placeholder="Retype Password"
                     autocomplete="off"
                     formControlName="passwordB"
              >
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('passwordB').errors)">
                {{error}}
              </div>
            </div>
          </div>

          <div class="row">
            <label class="col-12">Account Type</label>

            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input"
                       type="checkbox"
                       name="isB2BAccount"
                       id="user-add-isB2BAccount"
                       formControlName="isB2BAccount">
                <label class="custom-control-label" for="user-add-isB2BAccount">B2B Account</label>
              </div>
            </div>

            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input"
                       type="checkbox"
                       name="isHousingUserAccount"
                       id="user-add-isHousingUserAccount"
                       formControlName="isHousingUserAccount">
                <label class="custom-control-label" for="user-add-isHousingUserAccount">Hotel Account</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="block-content block-content-full bg-body-light">
    <div class="row">
      <div class="col-md-6 text-left"><button class="btn btn-danger" (click)="handleClickDelete()">Delete</button></div>
      <div class="col-md-6 text-right"><button class="btn btn-success" (click)="handleClick()">Save</button></div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'tripmakery-user-info-user-edit',
  templateUrl: './user-info-user-edit.component.html',
})
export class UserInfoUserEditComponent implements OnInit {
  public bookingChangeRequestId: string;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;

  @Input() user;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router
  ) {
    this.form = new FormGroup({
      'firstName': new FormControl({ value: '', disabled: false }),
      'lastName': new FormControl({ value: '', disabled: false }),
      'salutation': new FormControl({ value: '', disabled: false }),
      'language': new FormControl({ value: '', disabled: false }),
      'email': new FormControl({ value: '', disabled: false }),
      'callingCode': new FormControl({ value: '', disabled: false }),
      'phone': new FormControl({ value: '', disabled: false }),
      'passwordA': new FormControl({ value: '', disabled: false }),
      'passwordB': new FormControl({ value: '', disabled: false }),
      'isB2BAccount': new FormControl({ value: false, disabled: false }),
      'isHousingUserAccount': new FormControl({ value: false, disabled: false }),
    });
    this.environment_ = environment;
  }

  public ngOnInit() {
    this.form = new FormGroup({
      'firstName': new FormControl({ value: this.user.firstName, disabled: false }),
      'lastName': new FormControl({ value: this.user.lastName, disabled: false }),
      'salutation': new FormControl({ value: this.user.salutation, disabled: false }),
      'language': new FormControl({ value: this.user.preferences.language, disabled: false }),
      'email': new FormControl({ value: this.user.email, disabled: false }),
      'callingCode': new FormControl({ value: this.user.callingCode, disabled: false }),
      'phone': new FormControl({ value: this.user.phone, disabled: false }),
      'passwordA': new FormControl({ value: '', disabled: false }),
      'passwordB': new FormControl({ value: '', disabled: false }),
      'isB2BAccount': new FormControl({ value: this.createFalseIfNull(this.user.states.isB2B), disabled: false }),
      'isHousingUserAccount': new FormControl({ value: this.createFalseIfNull(this.user.states.isHousingUserAccount), disabled: false }),
    });
  }


  public handleClick() {
    this.showLoading();
    this.isEmailInUse = false;

    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/user/' + this.user._id, {
      firstName: this.createNullIfEmptyString(this.form.get('firstName').value),
      lastName: this.createNullIfEmptyString(this.form.get('lastName').value),
      salutation: this.createNullIfEmptyString(this.form.get('salutation').value),
      language: this.createNullIfEmptyString(this.form.get('language').value),
      email: this.createNullIfEmptyString(this.form.get('email').value),
      callingCode: this.createNullIfEmptyString(this.form.get('callingCode').value),
      phone: this.createNullIfEmptyString(this.form.get('phone').value),
      passwordA: this.createNullIfEmptyString(this.form.get('passwordA').value),
      passwordB: this.createNullIfEmptyString(this.form.get('passwordB').value),
      isB2BAccount: this.form.get('isB2BAccount').value,
      isHousingUserAccount: this.form.get('isHousingUserAccount').value,
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('userInfoReloadUserData');
          window.location.reload();
        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

                console.log(error.property, error.constraints);
              }
            } catch (error) {
              console.log('Catched E', error);
            }
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_EMAIL_ALREADY_IN_USE') {
            this.isEmailInUse = true;
            this.emailInUseUserId = err.error.userId;
            this.emailInUseUrl = environment.urls.tool + '/users/' + err.error.userId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  public handleClickDelete() {
    const text = 'Are you really sure you want to delete this user?';
    if (confirm(text) === true) {
      this.showLoading();
      this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/user/' + this.user._id)
        .subscribe(
          (response: any) => {
            this.eventsService.broadcast('userInfoReloadUserData');
            this.user = response.user;
            window.location.reload();
          },
          (err) => {
            console.log(err);

            this.hideLoading();
            this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
          },
          () => {
            this.hideLoading();
          }
        );
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfNull(element) {
    if (element == null) {
      return false;
    }
    return element;
  }

  public getTokenAndForwardToProTool(userId: string) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/user/' + userId + '/login-token', {})
      .subscribe(
        (response: any) => {
          const token = response.loginToken.token;
          const url = environment.urls.pro + '?token=' + token;
          window.open(url, '_blank');
        },
        (error) => {

        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

}

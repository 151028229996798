<app-housing-info-accountsystem *ngIf="housingInfoService.getSelectedHousing() != null" [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-accountsystem>

<h2 class="content-heading">Update Housing
  <button type="button" class="btn btn-sm btn-secondary float-right" *ngIf="housingInfoService.getSelectedHousing() != null && housingInfoService.getSelectedHousing().internalInfo.checkedByTeam === true">
    <i class="fa fa-check text-success mr-5"></i>Checked By Team
  </button>

  <a *ngIf="housingInfoService.getSelectedHousing() != null" class="btn btn-sm btn-primary mr-5 float-right" href="{{environment.urls.partner}}/de/h/{{housingInfoService.getSelectedHousing()._id}}/questions?admin=true" target="_blank">Questionary</a>
  <a *ngIf="housingInfoService.getSelectedHousing() != null" class="btn btn-sm btn-secondary mr-5 float-right" href="{{environment.urls.partner}}/de/h/{{housingInfoService.getSelectedHousing()._id}}?admin=true" target="_blank">Extranet</a>
  <a *ngIf="housingInfoService.getSelectedHousing() != null" class="btn btn-sm btn-info mr-5 float-right" href="{{environment.urls.pro}}/h/{{housingInfoService.getSelectedHousing()._id}}?testOffer=true" target="_blank">Pro Tool</a>
</h2>
<div class="row gutters-tiny">
  <div class="col-md-7">
    <!-- Basic Info -->
    <app-housing-info-basic-info [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-basic-info>
    <!-- END Basic Info -->

    <!--  Basic Info -->
    <app-housing-info-amenities [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-amenities>
    <!-- END Basic Info -->

    <!--  Basic Info -->
    <app-housing-info-rooms [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-rooms>
    <!-- END Basic Info -->

  </div>
  <!-- END Basic Info -->

  <div class="col-md-5">
    <!-- Google Maps -->
    <app-housing-info-google-maps [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-google-maps>
    <!-- END Google Maps -->

    <!-- Distances -->
    <app-housing-info-details-distances [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-distances>
    <!-- END Distances -->

    <!-- Rating -->
    <app-housing-info-rating [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-rating>
    <!-- END Rating -->

    <!-- Sauce -->
    <app-housing-info-details-sources [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-sources>
    <!-- END Sauce -->

    <!-- Sauce -->
<!--    <app-housing-info-details-onboarding-talks [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-onboarding-talks>-->
    <!-- END Sauce -->

    <!-- Sauce -->
<!--    <app-housing-info-details-channel-manager [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-channel-manager>-->
    <!-- END Sauce -->

    <!-- Sauce -->
<!--    <app-housing-info-details-channel-manager-settings [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-channel-manager-settings>-->
    <!-- END Sauce -->

    <!-- Instant Booking -->
<!--    <app-housing-info-details-cm-offer-reservations-available [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-cm-offer-reservations-available>-->
    <!-- END Instant Booking -->

    <!-- Instant Booking -->
    <app-housing-info-details-email-settings [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-email-settings>

    <!-- Instant Booking -->
    <app-housing-info-details-checked-by-team [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-checked-by-team>
    <!-- END Instant Booking -->

    <!-- Instant Booking -->
    <app-housing-info-details-deleted [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-deleted>
    <!-- END Instant Booking -->

    <!-- Instant Booking -->
    <app-housing-info-details-goodies [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-goodies>
    <!-- END Instant Booking -->

    <!-- Instant Booking -->
    <app-housing-info-details-whitelabel-configuration [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-whitelabel-configuration>
    <!-- END Instant Booking -->
  </div>

  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <app-housing-info-details-channel-manager-settings [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-channel-manager-settings>
        <app-housing-info-details-channel-manager-settings-hyperguest [housing]="housingInfoService.getSelectedHousing()" *ngIf="housingInfoService.getSelectedHousing() != null && housingInfoService.getSelectedHousing().channelManager.provider === 'hyperguest'" ></app-housing-info-details-channel-manager-settings-hyperguest>
        <app-housing-info-details-payment-settings [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-payment-settings>
      </div>
      <div class="col-md-6">
        <app-housing-info-details-onboarding-talks [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-details-onboarding-talks>
      </div>
    </div>
  </div>


  <div class="col-md-12">
    <!-- Images -->
    <app-housing-info-images [housing]="housingInfoService.getSelectedHousing()"></app-housing-info-images>
    <!-- END IMAGES -->
  </div>
</div>
